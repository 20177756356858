import axios from "axios";
import CryptoJS from "crypto-js";
import request from "../../request";
export const Apis_of_step_two = {
  auth_user_login: async (payload) => {
    const options = { method: 'POST' };
    var cipherKey = "Q5mo=2WMXKAwmTkbwBlftGd6LVZjVKXh";
    try {
      const response = await request(`${process.env.REACT_APP_BASE_URL}/api/generic/getRandomId`, options);
      if (response.status == "success") {
        let randomId = response.tokenId;
        // Decrypt randomId
        let randomIdDecrypted = CryptoJS.AES.decrypt(randomId, "Q5mo=2WMXKAwmTkbwBlftGd6LVZjVKXh").toString(CryptoJS.enc.Utf8);
        let userName = CryptoJS.AES.encrypt(payload.username, randomIdDecrypted).toString();
        let password = CryptoJS.AES.encrypt(payload.password, randomIdDecrypted).toString();
        userName = btoa(userName);
        password = btoa(password);
        randomIdDecrypted = btoa(randomIdDecrypted);
        var apiBody = {
          authObject: {
            username: userName,
            password: password,
            fingerprint: "1233444553223",
            tokenId: randomIdDecrypted
          },
          params: {
            ipInfo: {
              ip: ''
            },
            browserName: "Chrome"
          }
        }
        let encryptedResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/retailAppAuthenticate`, apiBody)
        let decryptedResponse = getDecryptedResponse(encryptedResponse.data)
        if (decryptedResponse.status === 'failure') {
          throw decryptedResponse?.message
        }
        else {
          return decryptedResponse
        }
      }
    } catch (error) {
      throw error
    }
  }
}

const getDecryptedResponse = response => {

  let responseJSON = response.result;
  responseJSON = JSON.parse(
    CryptoJS.AES.decrypt(
      responseJSON,
      "Q5mo=2WMXKAwmTkbwBlftGd6LVZjVKXh"
    ).toString(CryptoJS.enc.Utf8)
  );
  return responseJSON;
}