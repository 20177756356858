import { useState, useRef } from "react";
import Input from "../../lib/components/Input";
import TextArea from "../../lib/components/Textarea";
import DropDown from "../../lib/components/Dropdown";
import RadioInput from "../../lib/components/RadioInput";
import DatePicker from "../../lib/components/DatePicker";
import ComplexComponent from "../../components/ComplexComponent";
import "./index.scss";
import DragAndDrop from "../../lib/components/DragAndDrop";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { pinCodeAllocation } from "../../redux/OnboardingForm/OnboardingFormActions";
import {
  updateOnboardingFormData,
  generateOtp,
  validateOtp,
} from "../../redux/OnboardingForm/OnboardingFormActions";
import Separator from "../../lib/components/Separator";
import FormSubHeader from "../../components/FormSubHeader";
import RadioCheckbox from "../../lib/components/RadioCheckbox";
import AddTag from "../../components/AddTag";
import FormButtons from "./formButton";
import { validate } from "./validate";
import { scrollToTop } from "../../utils";
import Label from "../../lib/components/Label";

const DynamicForm = ({
  formFields,
  selectedData = {},
  setSelectedData,
  onBoardingForm=false,
  partnerDetails,
  handleNextClick,
  overlayForm,
  currentApplicant,
  handleNextValue,
  toggleFunction,
  handleSaveDraft,
  partnerInfo,
  partnerKyc,
  shop_finalized,
  shop_not_finalized,
  handleCancelClick,
  checklist,
  customAction = undefined,
  disabledFields = [],
  partner_documentation,
  ..._restProps
}) => {
  const [state, setState] = useState();
  const [formErr, setFormErr] = useState({});
  const dispatch = useDispatch();
  const stateErrRef = useRef();
  const selectedDataRef = useRef();

  stateErrRef.current = { ...formErr };
  selectedDataRef.current = { ...selectedData };

  const clearFieldError = (fields, isList) => {
    fields = isList ? fields : [fields];
    let err = { ...stateErrRef?.current };
    for (let key in fields) {
      const _field = fields[key];
      if (err[_field] !== undefined) {
        delete err[_field];
      }
    }
    setFormErr(err);
  };

  /*************************INPUT AND TEXTAREA */
  const handleChange = e => {
    const { name, value } = e.target;
    clearFieldError(name);
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
    dispatch(setSelectedData(name, value));
  };

  const onPincodeBlur = e => {
    dispatch(pinCodeAllocation(selectedData.pin_code));
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  /*************************DROPDOWN */

  const handleDropdown = (name, selectedVal) => {
    clearFieldError(name);
    dispatch(setSelectedData(name, selectedVal));
  };

  /*************************RADIO BUTTONS */
  const handleRadioChange = (selectedIdx, data) => {
    const { fieldIndex, name, options } = data;
    clearFieldError(name);
    const selectedVal = options[selectedIdx]?.label;
    dispatch(setSelectedData(name, selectedVal));
  };

  /*************************COMPLEX INPUT */
  const prepareGenerateOtpPaylaod = () => {
    const _selectedData = { ...selectedDataRef?.current };
    const otpPayload = {
      login_otp_mode: "mobile",
      email_id: _selectedData?.email,
      phoneNumber: _selectedData?.mobile_number,
      organisation_id: process.env.REACT_APP_ORGANIZATION_ID,
      type: "login",
      loginType: "retailApp",
    };
    return otpPayload;
  };

  const prepareValidateOtpPaylaod = otp => {
    const otpPayload = {
      login_otp_mode: "mobile",
      email_id: selectedData?.email,
      phoneNumber: selectedData?.mobile_number,
      otp: otp,
      organisation_id: process.env.REACT_APP_ORGANIZATION_ID,
      type: "login",
      loginType: "retailApp",
    };
    return otpPayload;
  };

  const handleComplexChange = (data, resendOtp = false) => {
    if (resendOtp) {
      dispatch(generateOtp(prepareGenerateOtpPaylaod()));
      return;
    }
    const { fieldIndex } = data;
    let tempFields = { ...formFields };
    tempFields["fields"][fieldIndex] = data;
    dispatch(updateOnboardingFormData(tempFields));
    dispatch(generateOtp(prepareGenerateOtpPaylaod()));
  };

  const sendForOtpValidation = enteredOtp => {
    dispatch(validateOtp(prepareValidateOtpPaylaod(enteredOtp), ()=>{
      clearFieldError("mobile_number");
    }));
  };

  /********************** RADIOCHECKBOX*/
  const handleRadioCheckbox = (name, value) => {
    clearFieldError(name);
    dispatch(setSelectedData(name, value));
  };

  /********************** SUBMIT BUTTONS*/
  const [emptyFieldMsg, setemptyFieldMsg] = useState(false);
  const handleButtonClick = () => {
    handleNextClick();
  };

  /********************** UPLOAD*/
  const handleUpload = (name, val) => {
    clearFieldError(name);
    dispatch(setSelectedData(name, val));
  };

  /********************** ADD TAG*/
  const handleAddTag = (name, val) => {
    clearFieldError(name);
    dispatch(setSelectedData(name, val));
  };

  const FieldMap = {
    text: {
      component: Input,
      onChange: handleChange,
      onPinBlur: onPincodeBlur,
    },
    number: {
      component: Input,
      onChange: handleChange,
      onPinBlur: onPincodeBlur,
    },
    upload: {
      component: DragAndDrop,
      handleUpload: handleUpload,
      clearFieldError,
    },
    date: { component: DatePicker, onChange: handleChange },
    complex: {
      component: ComplexComponent,
      onChange: handleComplexChange,
      validateOtp: sendForOtpValidation,
      clearFieldError: clearFieldError
    },
    radio: { component: RadioInput, onChange: handleRadioChange },
    dropdown: { component: DropDown, onChange: handleDropdown },
    textarea: { component: TextArea, onChange: handleChange },
    separator: { component: Separator },
    subHeader: { component: FormSubHeader },
    radioCheckbox: {
      component: RadioCheckbox,
      handleRadioCheckbox: handleRadioCheckbox,
    },
    addTag: {
      component: AddTag,
      handleAddTag: handleAddTag,
    },
    label: { component: Label },
  };

  const renderFields = data => {
    // console.log(data.enum)
    const { component: Component, ...restProps } = FieldMap[data?.type];
    const finalProps = {
      ...restProps,
      ...data.error_messages,
      ...data,
      enums: data?.enum
    };
    return <Component {...finalProps} />;
  };
  const checkFieldVisibility = field => {
    const dependent_field = field?.dependent;
    if (dependent_field?.field_name?.length !== 0) {
      const selectedDepVal = selectedData?.[dependent_field?.field_name];
      return selectedDepVal === dependent_field?.val;
    }
    return true;
  };
  const getSelectedValue = item => {
    const { type, name } = item;
    if (type === "dropdown") {
      const selectedOption = item?.options?.find(_item => {
        return _item.label === selectedData[name];
      });
      return selectedOption;
    }
    if (type === "radio") {
      const selectedOptionIdx = item?.options?.findIndex(item => {
        return item.label === selectedData[name];
      });
      return selectedOptionIdx;
    }
    return selectedData[name];
  };
  const validateAndSubmit = () => {
    const restData = { onBoardingForm: onBoardingForm};
    const errors = validate(formFields?.fields, selectedData, restData);

    const errKeys = Object.keys(errors);

    if (errKeys?.length === 0) {
      handleNextClick();
      return;
    } else {
      setFormErr(errors);
      scrollToTop();
    }
  };
  return (
    <>
      <section
        className={
          overlayForm === true
            ? "dynamic-form-overlayForm"
            : "dynamic-form-wrapper"
        }
      >
        <Row>
          {formFields?.title && (
            <Col lg={12}>
              <h6 className="dynamic-form-title">{formFields?.title}</h6>
            </Col>
          )}
          {formFields?.fields?.map((item, index) => {
            let { name } = item;
            const value = getSelectedValue(item);
            const error = formErr[name]?.err || "";
            let isDisabled = false;
            let custom_action = undefined;
            let verified = false;
            let restProps = {};
            isDisabled = disabledFields.indexOf(name) !== -1;
            {
              /* Mobile field specific start */
            }
            if (name === "mobile_number") {
              const { otp_verified, otp_generated } = selectedData;
              if (otp_verified) {
                item.grid_col = item.verified_grid_col
                  ? item.verified_grid_col
                  : item.grid_col;
                isDisabled = true;
                custom_action = customAction;
                verified = true;
              } else if (otp_generated) {
                isDisabled = true;
                custom_action = customAction;
              } else {
                item.grid_col = item.default_grid_col || item.grid_col;
              }
            }
            {
              /* Mobile field specific end */
            }

            if (customAction && customAction?.field === name) {
              restProps = { ...restProps, custom_action: customAction };
            }
            if (_restProps?.otherProps) {
              _restProps.otherProps?.map(_fieldProps => {
                let _newProps = { ..._fieldProps };
                if (_newProps.field === name) {
                  delete _newProps.field;
                  restProps = { ...restProps, ..._newProps };
                }
              });
            }
            const isRenderAllowed = checkFieldVisibility({
              ...item,
              ...restProps,
            });
            return (
              isRenderAllowed && (
                <Col lg={item?.grid_col} key={index}>
                  {renderFields({
                    ...item,
                    fieldIndex: index,
                    value,
                    error,
                    isDisabled,
                    custom_action,
                    verified,
                    ...restProps,
                  })}
                </Col>
              )
            );
          })}
        </Row>
        {_restProps?.children}
        <FormButtons
          onBoardingForm={onBoardingForm}
          partnerDetails={partnerDetails}
          currentApplicant={currentApplicant}
          handleNextClick={validateAndSubmit}
          handleNextValue={handleNextValue}
          toggleFunction={toggleFunction}
          handleSaveDraft={handleSaveDraft}
          partnerKyc={partnerKyc}
          partnerInfo={partnerInfo}
          shop_not_finalized={shop_not_finalized}
          shop_finalized={shop_finalized}
          handleCancelClick={handleCancelClick}
          checklist={checklist}
          partner_documentation={partner_documentation}
        />
      </section>
    </>
  );
};
export default DynamicForm;
