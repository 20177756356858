import axios from "axios";
import {
  UPDATE_WORKFLOW_ENDPOINT,
  UPLOAD_PAN_ENDPOINT,
  UPLOAD_KYC_ENDPOINT,
  OTP_GENERATE_ENDPOINT,
  OTP_VALIDATE_ENDPOINT,
  SUBMIT_ONBOARD_FRANCHISE_USER,
  PINCODE_ALLOCATION_ENDPOINT,
  VALIDATE_AADHAR_ENDPOINT,
  VALIDATE_VOTERID_ENDPOINT,
  VALIDATE_PASSPORT_ENDPOINT,
  UPLOAD_DOCUMENT_ENDPOINT,
  VALIDATE_DRIVING_LICENSE_ENDPOINT,
} from "../../constants/api.constants.js";
import { WORKFLOW_ID } from "../../constants/app.constants";
import { SUCCESS_RESPONSE_LIST } from "../../constants/status.js";
import request from "../../utils/request";
import { parseDateOfBirth } from "../../utils/onBoardingHelper";
import {
  FETCH_ONBOARDING_FORM_REQUEST,
  FETCH_ONBOARDING_FORM_SUCCESS,
  FETCH_ONBOARDING_FORM_FAILURE,
  UPDATE_ONBOARDING_FORM_DATA,
  SET_SELECTED_DATA,
  UPLOAD_PAN_REQUEST,
  UPLOAD_PAN_SUCCESS,
  UPLOAD_PAN_FAILURE,
  UPLOAD_KYC_SUCCESS,
  GENERATE_OTP_REQUEST,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILURE,
  VALIDATE_OTP_REQUEST,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_FAILURE,
  SET_DATA_FROM_PANCARD,
  RESET_ONBOARDING_FORM_DATA,
  FORM_ERROR,
  VALIDATE_ID_PROOF,
  SET_PINCODE_ALLOCATION,
  RESET_MOBILE_AND_OTP,
  SAVE_LOCALITY
} from "./OnboardingFormTypes";
import pan_mock from "./pan_mock.json";
import { toast } from 'react-hot-toast'

import { setExtractedPanCardDataForPartnerKYC } from '../PartnerKYC/partnerKYCActions'
import { APPLICATION_STATUSES } from "../../constants/status.constants.js";
import { setAppError, setOnBoardingDisabledFields, setPageLoader, setSubmitLoading } from "../App/Action.js";
import requestV1 from "../../utils/requestV1.js";

export const fetchOnboardingFormFields = () => async (dispatch) => {
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/getInitialForm`;
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: {}
  };
  dispatch(fetchOnboardingFormRequest());
  try {
    const result = await requestV1(reqUri, options);
    // const result = mock_data;
    const { fields, module, title } = result.result;
    const formFields = {
      configuration: {
        step_1: {
          module: module,
          title: title,
          fields: fields,
        },
      },
    };
    dispatch(fetchOnboardingFormSuccess(formFields));
  } catch (error) {
    dispatch(setAppError(true))
    toast.error("Network Error")
  }
};

/**not used */
export const uploadPan = () => async (dispatch) => {
  const reqUri = UPLOAD_PAN_ENDPOINT;
  dispatch(uploadPanRequest());
  try {
    const result = await request(reqUri);
    const { status, data } = result;
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      const { pan_number, name, dob } = data;
      const pan_data = {
        pan: pan_number,
        first_name: name,
        date_of_birth: dob,
      };
      dispatch(uploadPanSuccess(pan_data));
    }
  } catch (error) {
    const { pan_number, name, dob } = pan_mock?.data;
    const pan_data = { pan: pan_number, first_name: name, date_of_birth: dob };
    // dispatch(uploadPanFailure(error.message));
    dispatch(uploadPanSuccess(pan_data));
  }
};

const pan_extracted_fields = ['pan_number', 'first_name', 'last_name', 'date_of_birth'];

// Action to work with new endpoint === https://signdesk.co:2023/api
export const extractDataFromPancard = (uploadData, cb, errCb) => async (dispatch) => {
  // toast.success("Pan uploaded successfully. Please wait while we prefill the data for you")
  const reqUri = UPLOAD_DOCUMENT_ENDPOINT;
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(uploadData)
  };
  dispatch(uploadPanRequest());
  dispatch(setOnBoardingDisabledFields(pan_extracted_fields, "add"));
  try {
    const response = await requestV1(reqUri, options)
    const { status, result, message } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      const { document_number, given_names, date_of_birth, fathers_name, surname } = result
      const pan_data = {
        pan_number: document_number,
        first_name: given_names,
        last_name: surname,
        father_name: fathers_name,
        date_of_birth: parseDateOfBirth(date_of_birth),
      };

      dispatch(setExtractedPanCardData(pan_data));
      dispatch(setExtractedPanCardDataForPartnerKYC(pan_data));
      cb(pan_extracted_fields);

    } else if (status === "failed") {
      // toast.error(response?.data?.message)
      errCb();
      dispatch(formErrors(response))
    }
    dispatch(setOnBoardingDisabledFields(pan_extracted_fields, "remove"));
  }
  catch (err) {
    dispatch(uploadPanFailure(err.message));
    dispatch(setOnBoardingDisabledFields(pan_extracted_fields, "remove"));
  }
  // axios
  //   .post(reqUri, uploadData)
  //   .then((response) => {
  //     const { status, result } = response.data;
  //     if (SUCCESS_RESPONSE_LIST.includes(status)) {
  //       const { pan_number, name, dob } = result.extracted_data;
  //       const pan_data = {
  //         pan_number: pan_number,
  //         first_name: name?.split(" ")?.slice(0, -1)?.join(" "),
  //         last_name: name?.split(" ")?.slice(-1)?.join(" "),
  //         date_of_birth: parseDateOfBirth(dob),
  //       };

  //       dispatch(setExtractedPanCardData(pan_data));
  //       dispatch(setExtractedPanCardDataForPartnerKYC(pan_data));
  //       cb(pan_extracted_fields);

  //     } else if (response?.data?.status === "failed") {
  //       // toast.error(response?.data?.message)
  //       errCb();
  //       dispatch(formErrors(response.data))
  //     }
  //     dispatch(setOnBoardingDisabledFields(pan_extracted_fields, "remove"));
  //   })
  //   .catch((err) => {
  //     dispatch(uploadPanFailure(err.message));
  //     dispatch(setOnBoardingDisabledFields(pan_extracted_fields, "remove"));
  //   });
};

// Action to work with new endpoint === https://signdesk.co:2023/api
export const generateOtp = (dataToGenerateOtp) => async (dispatch) => {
  const reqUri = OTP_GENERATE_ENDPOINT;
  dispatch(generateOtpRequest());
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataToGenerateOtp)
  };

  try {
    if (!dataToGenerateOtp.phoneNumber) {
      return toast.error("Please add mobile number")
    }
    const response = await requestV1(reqUri, options)
    const { status, sms_trigger, email_trigger, message } = response;
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      const otpGenerationConfirmed = sms_trigger || email_trigger;
      dispatch(generateOtpSuccess(otpGenerationConfirmed));
      toast.success(message)

    } else {
      dispatch(formErrors(response))
      toast.error(response?.message)
      dispatch(generateOtpFailure(message));
    }

    // axios
    //   .post(reqUri, dataToGenerateOtp)
    //   .then((response) => {
    //     const { status, sms_trigger, email_trigger } = response.data;
    //     if (SUCCESS_RESPONSE_LIST.includes(status)) {
    //       const otpGenerationConfirmed = sms_trigger || email_trigger;
    //       dispatch(generateOtpSuccess(otpGenerationConfirmed));
    //       toast.success(response?.data?.message)

    //     } else {
    //       dispatch(formErrors(response.data))
    //       toast.error(response?.data?.message)
    //     }
    //   })
    //   .catch((err) => {
    //     dispatch(generateOtpFailure(err.message));
    //   });
  } catch (err) {
    toast.error(err)
  }
};

// Action to work with new endpoint === https://signdesk.co:2023/api
export const validateOtp = (dataToValidteOtp, successCb) => async (dispatch) => {
  const reqUri = OTP_VALIDATE_ENDPOINT;
  dispatch(validateOtpRequest());
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataToValidteOtp)
  };

  try {
    const response = await requestV1(reqUri, options)
    const { message, status, result } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      toast.success("OTP verified successfully")
      dispatch(validateOtpSuccess(true));
      dispatch(phoneValidation(true));
      successCb();
    } else {
      toast.error(message)
      dispatch(formErrors(result))
      dispatch(phoneValidation(false))
      dispatch(validateOtpSuccess(false));
      dispatch(validateOtpFailure(message));
    }
  }
  catch (err) {
    toast.error("Network Error")
  }
  // axios
  //   .post(reqUri, dataToValidteOtp)
  //   .then((response) => {
  //     const { status } = response?.data;
  //     if (SUCCESS_RESPONSE_LIST.includes(status)) {
  //       toast.success("OTP verified successfully")
  //       dispatch(validateOtpSuccess(true));
  //       dispatch(phoneValidation(true))
  //     } else {
  //       toast.error(response?.data?.message)
  //       dispatch(formErrors(response.data))
  //       dispatch(phoneValidation(false))
  //       dispatch(validateOtpSuccess(false));
  //     }
  //   })
  //   .catch((err) => {
  //     dispatch(validateOtpFailure(err.message));
  //   });
};

const determineRequestUri = (idProofType) => {

  switch (idProofType) {
    case "Driving Lisence":
      return VALIDATE_DRIVING_LICENSE_ENDPOINT;
    case "Addhaar Card":
      return VALIDATE_AADHAR_ENDPOINT;
    case "Voter ID":
      return VALIDATE_VOTERID_ENDPOINT;
    case "Passport":
      return VALIDATE_PASSPORT_ENDPOINT;
    default:
      return VALIDATE_AADHAR_ENDPOINT;
  }
};

// Action to work with new endpoint === https://signdesk.co:2023/api
export const validateIdentityProof =
  (sourceArray, idproofType, errCb) => async (dispatch) => {
    let validatePayload;
    const reqUri = determineRequestUri(idproofType);
    if (idproofType === "Aadhaar Card") {
      validatePayload = {
        organisation_id: process.env.REACT_APP_ORGANIZATION_ID,
        documents: [
          {
            type: "front_side_of_aadhaar",
            doc_value: "Aadhaar",
            content: sourceArray[1],
            format: "JPG",
          },
          {
            type: "back_side_of_aadhaar",
            doc_value: "Aadhaar",
            content: sourceArray[0],
            format: "JPG",
          },
        ],
        type: "JPEG",
        status: "pending",
        is_editable: true,
        reference_id: "fdhdusujsddeees",
        source_type: "base64",
      };
    } else {
      validatePayload = {
        organisation_id: process.env.REACT_APP_ORGANIZATION_ID,
        source: sourceArray,
        type: "JPEG",
        status: "pending",  
        is_editable: true,
        reference_id: "fdhdusujsddeees",
        source_type: "base64",
      };
    }

    dispatch(validateOtpRequest());
    const options = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(validatePayload)
    };

    try {
      if (idproofType !== undefined) {
        const response = await requestV1(reqUri, options)
        const { message, result, reference_id, transaction_id, status } = response
        if (SUCCESS_RESPONSE_LIST.includes(status)) {
          //otp validate success issue fix
          // dispatch(validateOtpSuccess(true));
        } else if (status === "failed") {
          errCb();
          // response?.data?.message ? toast.error(response?.data?.message) : toast.error(response?.data?.error)
          // dispatch(validateOtpSuccess(false));
          dispatch(validateIdProof(false));
          // dispatch(formErrors(response.data))
        } else {
          dispatch(validateIdProof(true));
          // dispatch(formErrors(response.data))
          toast.error(message)
          dispatch(validateOtpFailure(message));
        }
      }
    }
    catch (err) {
      toast.error("Network Error")
    }
    // if (idproofType !== undefined) {
    //   axios
    //     .post(reqUri, validatePayload)
    //     .then((response) => {
    //       const { status } = response.data;
    //       if (SUCCESS_RESPONSE_LIST.includes(status)) {
    //         //otp validate success issue fix
    //         // dispatch(validateOtpSuccess(true));
    //       } else if (response?.data?.status === "failed") {
    //         errCb();
    //         // response?.data?.message ? toast.error(response?.data?.message) : toast.error(response?.data?.error)
    //         // dispatch(validateOtpSuccess(false));
    //         dispatch(validateIdProof(false));
    //         // dispatch(formErrors(response.data))
    //       } else {
    //         dispatch(validateIdProof(true));
    //         // dispatch(formErrors(response.data))
    //         toast.error(response?.data?.message)
    //       }
    //     })
    //     .catch((err) => {
    //       dispatch(validateOtpFailure(err.message));
    //     });
    // }
  };

export const pinCodeAllocation = (pincode) => async (dispatch) => {
  const reqUri = PINCODE_ALLOCATION_ENDPOINT;
  const pincodeAllocationData = {
    organisation_id: process.env.REACT_APP_ORGANIZATION_ID,
    pin_code: pincode,
    role: "Cluster Head",
  };
  dispatch(validateOtpRequest());
  axios
    .post(reqUri, pincodeAllocationData)
    .then((response) => {
      const { status } = response.data;
      if (SUCCESS_RESPONSE_LIST.includes(status)) {
        dispatch(pincodeAllocation(true));
        dispatch(assignedToClusterHead(response?.data?.result?._id));
      } else {
        dispatch(pincodeAllocation(false));
      }
    })
    .catch((err) => {
      dispatch(validateOtpFailure(err.message));
    });
};

export const submitOnBoardingForm = (data, cb) => async (dispatch) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...data, workflow_configuration_id: process.env.REACT_APP_WORKFLOW_CONFIGURATION_ID }),
  }; //move to request
  const reqUri = UPDATE_WORKFLOW_ENDPOINT; //remove hardcoded data
  dispatch(submitFormRequest());
  try {
    const result = await request(reqUri, options);
    const { status } = result;
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      dispatch(submitFormSuccess());
      cb();
    }
  } catch (error) {
    // dispatch(submitFormFailure());
    dispatch(submitFormSuccess());
    cb();
  }
};

export const submitOnBoardingFranchise = (data, cb, kycFiles, clusterHeadAssignment, clientRefId) => async (dispatch) => {
  data.email = data.email.trim().toLowerCase()
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email_id: data.email,
      first_name: data.first_name,
      id_proof: data.id_proof,
      last_name: data.last_name,
      mobile_number: data.mobile_number,
      organisation_id: process.env.REACT_APP_ORGANIZATION_ID,
      role: "61a498bd1a13c808d0cc46b9",
      type: "retailApp",
      userType: "B2B",
      isActive: true
    }),
  }; //move to request
  const reqUri = SUBMIT_ONBOARD_FRANCHISE_USER;
  dispatch(submitFormRequest());
  try {
    dispatch(setSubmitLoading(true))
    const response = await requestV1(reqUri, options);
    const { status, message, result, user } = response;
    let userId = null
    if(user){
      userId = user?._id
    }else if(result){
      userId = result?._id
    }
    dispatch(submitUserSubmissionToSRLServer(data, kycFiles, clusterHeadAssignment, clientRefId, userId, cb));
  } catch (error) {
    dispatch(submitFormSuccess());
    cb();
  }
};

export const assignedToClusterHead = (id) => {
  return { type: "ASSIGNED_TO_CLUSTER_HEAD", payload: id };
};

export const submitUserSubmissionToSRLServer = (data, kycFiles, clusterHeadAssignment, clientRefId, userId, cb) => async (dispatch) => {
  let formData = new FormData();
  try {
    for (let file in kycFiles) {
      formData.append(file, kycFiles[file])
    }
    delete data?.applicant_photo
    delete data?.id_proof_back
    delete data?.id_proof_back_base64
    delete data?.id_proof_front_base64
    delete data?.id_proof_front
    delete data?.pan

    function toTitleCase(str) {
      return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
      }).join(' ');
    }

    data.first_name = toTitleCase(data.first_name);
    data.last_name = toTitleCase(data.last_name);
    data.father_name = toTitleCase(data.father_name);
    data.district = toTitleCase(data.district);

    const signdesk_data_payload = {
      user_id: userId || "",
      workflow_configuration_id: process.env.REACT_APP_WORKFLOW_CONFIGURATION_ID,
      status: APPLICATION_STATUSES['Awaiting CH Approval']['label'],
      active_step: 2,
      is_deleted: false,
      stepHistory: [
        {
          step: 1,
          status: "COMPLETED",
          initated_date: new Date().toDateString(),
          completed_date: new Date().toDateString(),
          completed_by: userId || ""
        },
        {
          step: 2,
          status: "PENDING",
          initated_date: new Date().toDateString(),
        }
      ],
      
      source_data: {
        ...data,
        // pin_code:data?.pin_code
      },
      document_signers: [],
      clarification_history: [],
      assigned_to: clusterHeadAssignment || '', //"61a4e4143ad89441ee025c98
      created_at: "2021-12-03T05:26:58.838Z",
      reject_remarks: [],
      clarification_history: [],
      client_reference_id: clientRefId,
      organisation_id: process.env.REACT_APP_ORGANIZATION_ID
    }
    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/retailWorkflow/submit-data`;
    let params = {
      body: JSON.stringify(signdesk_data_payload)
    }
    const encryptedBodyRes = await requestV1('', params, true, true);

    formData.append('data', JSON.stringify(encryptedBodyRes))
    const options = {
      method: "POST",
      body: formData
    };

    let response = await requestV1(reqUri, options, false, true);

    const { status, message } = response;
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      dispatch(setSubmitLoading(false))
      dispatch(submitFormSuccess());
      cb();
      toast.success(message)
    } else {
      dispatch(setSubmitLoading(false))
      toast.error(message)
    }
  } catch (error) {
    toast.error("Network Error",)
    dispatch(setSubmitLoading(false))
  }
}

// Api endpoint is a confusion
export const fetchOnboardingFormRequest = () => {
  return {
    type: FETCH_ONBOARDING_FORM_REQUEST,
  };
};
export const fetchOnboardingFormSuccess = (data) => {
  return {
    type: FETCH_ONBOARDING_FORM_SUCCESS,
    payload: data,
  };
};

export const fetchOnboardingFormFailure = (error) => {
  return {
    type: FETCH_ONBOARDING_FORM_FAILURE,
    payload: error,
  };
};

export const updateOnboardingFormData = (data) => {
  return {
    type: UPDATE_ONBOARDING_FORM_DATA,
    payload: data,
  };
};

export const setSelectedData = (key, value) => {
  return {
    type: SET_SELECTED_DATA,
    key,
    payload: value,
  };
};

export const setFileObjects = (key, value, isRemove) => {
  return {
    type: "SET_FILE_OBJECT",
    key,
    payload: value,
    isRemove
  };
};
export const setUploadedDocs = (key, value, isRemove) => {
  return {
    type: "SET_UPLOADED_DOCS",
    key,
    payload: value,
    isRemove
  };
};

// Pan Upload action helpers.
export const uploadPanRequest = () => {
  return {
    type: UPLOAD_PAN_REQUEST,
  };
};

export const uploadPanSuccess = (data) => {
  return {
    type: UPLOAD_PAN_SUCCESS,
    payload: data,
  };
};

export const setExtractedPanCardData = (data) => {
  return {
    type: SET_DATA_FROM_PANCARD,
    payload: data,
  };
};


export const uploadKycSuccess = (data) => {
  return {
    type: UPLOAD_KYC_SUCCESS,
    payload: data,
  };
};

export const uploadPanFailure = (error) => {
  return {
    type: UPLOAD_PAN_FAILURE,
    payload: error,
  };
};

// otp generation action helpers.
export const generateOtpRequest = () => {
  return {
    type: GENERATE_OTP_REQUEST,
  };
};

export const generateOtpSuccess = (data) => {
  return {
    type: GENERATE_OTP_SUCCESS,
    payload: data,
  };
};

export const generateOtpFailure = (error) => {
  return {
    type: GENERATE_OTP_FAILURE,
    payload: error,
  };
};

// otp validation action helpers.
export const validateOtpRequest = () => {
  return {
    type: VALIDATE_OTP_REQUEST,
  };
};

export const validateOtpSuccess = (data) => {
  return {
    type: VALIDATE_OTP_SUCCESS,
    payload: data,
  };
};

export const resetOtpSuccess = (data) => {
  return {
    type: VALIDATE_OTP_SUCCESS,
    payload: "init_state",
  };
};

export const validateOtpFailure = (error) => {
  return {
    type: VALIDATE_OTP_FAILURE,
    payload: error,
  };
};

export const resetOtpGenerationStatus = () => {
  return {
    type: GENERATE_OTP_SUCCESS,
    payload: false,
  };
};

export const submitFormRequest = () => {
  return {
    type: UPLOAD_PAN_REQUEST,
  };
};

export const submitFormSuccess = (data) => {
  return {
    type: UPLOAD_PAN_SUCCESS,
  };
};

export const submitFormFailure = (error) => {
  return {
    type: UPLOAD_PAN_FAILURE,
    payload: error,
  };
};

export const resetOnboardingForm = (error) => {
  return {
    type: RESET_ONBOARDING_FORM_DATA,
    payload: error,
  };
};


export const formErrors = (error) => {
  return {
    type: FORM_ERROR,
    payload: error,
  };
};

export const phoneValidation = (data) => {
  return {
    type: "PHONE_VALIDATION",
    payload: data
  }
}

export const validateIdProof = (data) => {
  return {
    type: VALIDATE_ID_PROOF,
    payload: data,
  };
};

export const storeClientRefId = (ID) => {
  return {
    type: "STORE_CLIENT_REF_ID",
    payload: ID,
  }
}

export const pincodeAllocation = (data) => {
  return {
    type: SET_PINCODE_ALLOCATION,
    payload: data,
  };
};

export const resetMobileAndOtp = () => {
  return {
    type: RESET_MOBILE_AND_OTP
  }
}

export const fetchPinCodeInfo = (pincode, shop_finalized) => async (dispatch) => {
  if(pincode.toString().length === 6){
  const reqUri = `${process.env.REACT_APP_BASE_URL}/api/document/getPincodeInfo`
  const options = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ "pin_code": pincode.toString() })
  };
  try {
    const response = await requestV1(reqUri, options)
    const { status, result, message } = response
    if (SUCCESS_RESPONSE_LIST.includes(status)) {
      if (shop_finalized.toLowerCase() === "yes") {
        dispatch(setSelectedData('district', result?.district))
        dispatch(saveLocalityData(result?.locality))
      }
      else {
        dispatch(setSelectedData('district', result?.district))
        dispatch(saveLocalityData(result?.locality))
      }
      dispatch(setSelectedData("state", result?.state))
    }
    else {
      toast.error(message)
    }
  }
  catch (err) {
    toast.error("Network Error")
  }
}
}

const saveLocalityData = (payload) => {
  return {
    type: SAVE_LOCALITY,
    payload: payload
  }
}