import request from "./request";
import * as crypto from "crypto-js";

var decryptedTokenId = null;
const getDecryptedResponse = response => {
  let decTokenId = null;
  const { token_id } = response;
  if (token_id) {
    decTokenId = crypto.AES.decrypt(
      token_id,
      "Q5mo=2WMXKAwmTkbwBlftGd6LVZjVKXh"
    ).toString(crypto.enc.Utf8);
      let responseJSON = response.result;
      responseJSON = JSON.parse(
        crypto.AES.decrypt(
          responseJSON,
          "Q5mo=2WMXKAwmTkbwBlftGd6LVZjVKXh"
        ).toString(crypto.enc.Utf8)
      );
      return responseJSON;
  } else {
    let responseJSON = response.result;
    responseJSON = JSON.parse(
      crypto.AES.decrypt(
        responseJSON,
        "Q5mo=2WMXKAwmTkbwBlftGd6LVZjVKXh"
      ).toString(crypto.enc.Utf8)
    );
    return responseJSON;
  }
};

async function requestV1(
  url = "",
  options = { body: {} },
  encrypt = true,
  encryptedBodyReq = false
) {
  if (encrypt) {
    // console.log("inside if");
    const reqUri = `${process.env.REACT_APP_BASE_URL}/api/generic/getRandomId`;
    let option = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const result = await request(reqUri, option);
      const { status, tokenId } = result;
      // console.log(status);
      if (status === "success") {
        decryptedTokenId = crypto.AES.decrypt(
          tokenId,
          "Q5mo=2WMXKAwmTkbwBlftGd6LVZjVKXh"
        ).toString(crypto.enc.Utf8);
        const finalBody = JSON.stringify(options.body);
        let encryptedBody = crypto.AES.encrypt(
          finalBody,
          decryptedTokenId
        ).toString();
        decryptedTokenId = btoa(decryptedTokenId);
        let encryptedAPIParams = {
          api_body: encryptedBody,
          token_id: decryptedTokenId,
        };
        let _options = {...options};
        _options.body = JSON.stringify(encryptedAPIParams);
        if (encryptedBodyReq) {
          return { ...encryptedAPIParams, ...{ decrypt: true } };
        } else {
          _options.body = JSON.stringify(encryptedAPIParams);
          let resultV1 = await request(url, _options);
          resultV1 = getDecryptedResponse(resultV1);
          // console.log("heere", resultV1)

          return resultV1;
        }
      }
    } catch (error) {
      // console.log("Request error:", error);
    }
  } else if (!encrypt && encryptedBodyReq) {
    let result = await request(url, options);
    result = getDecryptedResponse(result);
    // console.log("inside elseif");
    return result;
  } else {
    // console.log("inside else");
    request(url,options).then(res=>{
      // console.log(res)
      return res
    });
    return request(url, options);
  }
}

export default requestV1;
